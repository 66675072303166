/*
  There are a lot of similar top-top-level components, which
    just wrappers above more specific childs with unified general
    functionality related to the visitor page.
  Please dont combine them into one super-components, I think
    in this style they are more flexible and maintainable.
 */

import * as React from 'react';

import { VisitorPageSection } from '../VisitorPageSection';
import { ChallengeShare } from '../../../../components/ChallengeShare';
import { ChallengeAgenda } from '../../../../components/ChallengeAgenda';
import { SocialGroup } from '../../../../../../../components-shared/SocialGroup';
import { VisitorPageProgramDetails } from './VisitorPageProgramDetails';
import { Pricing } from '../../../../components/Pricing/Pricing';
import { JoinButtonIds, VisitorPageJoinButton } from './VisitorPageJoinButton';

import { useChallengeData } from '../../../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useSettings } from '@wix/tpa-settings/react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { useSocialGroups } from '../../../../../../../contexts/SocialGroups/SocialGroupsContext';
import { useUser } from '../../../../../../../contexts/User/UserContext';
import { useMemberPaidPlans } from '../../../../../../../contexts/PaidPlans/paidPlansContext';
import { useVisitorPageBase } from '../../contexts/VisitorPageBase/VisitorPageBaseContext';

import { SettingsKeysAbbreviation } from '../../../../../Settings/constants/settingsKeysAbbreviation';
import settingsParams from '../../../../../settingsParams';
import {
  isNeedToSelectStartDate,
  isPricingPickingForbidden,
} from '../../../../../../../selectors/challenges';
import {
  FCWithChildren,
  getChallengePaidPlans,
  isChallengeFree,
} from '@wix/challenges-web-library';
import userTypeHandlers from '../../../../../../../contexts/User/helpers/userTypeHandlers';

import { visitorPageDataHooks } from '../../VisitorPage';
import { sectionIds } from '../../../../../Settings/tabs/Sections/components/VisitorV2SectionsTabContent/constants/sectionIds';
import { TextAlignment } from '../../../../../Settings/challengeSettings/challengeSettings.types';
import { VisitorPageInstructors } from './VisitorPageInstructors';
import { useSidebarItems } from '../../../../../Settings/tabs/Sections/components/VisitorV2SectionsTabContent/constants/splitItemsHelper';
import { Challenges } from '../../../../../../../editor/types/Experiments';
import { st, classes } from './VisitorPageSections.st.css';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { ProgramAccessType } from '../../../../../../../types/programs';

const DateInput = loadable(
  () => import('../../../../../../../components-shared/DateInput/DateInput'),
);

export const VisitorPageShare: FCWithChildren<{
  className?: string;
  alignment?: TextAlignment;
}> = ({ className = null, alignment = TextAlignment.Left }) => {
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const showSocial =
    cssPBEnabled || settings.get(settingsParams.socialSharingIsEnabled);
  const isVisible =
    showSocial &&
    (challenge?.settings?.accessRestrictions?.accessType as any) !==
      ProgramAccessType.SECRET;
  const showTitle =
    cssPBEnabled ||
    settings.get(
      settingsParams[SettingsKeysAbbreviation.visitorPageToggleShareTitle],
    );
  const title = showTitle
    ? settings.get(
        settingsParams[SettingsKeysAbbreviation.visitorPageShareTitle],
      )
    : null;

  return isVisible ? (
    <VisitorPageSection
      className={st(
        className,
        cssPBEnabled ? classes.opgSocialSharingIsEnabled : undefined,
      )}
      title={title}
      alignment={alignment}
    >
      <ChallengeShare alignment={alignment} />
    </VisitorPageSection>
  ) : null;
};

export const VisitorPageAgenda: FCWithChildren<{
  className?: string;
  alignment?: TextAlignment;
}> = ({ className = null, alignment = TextAlignment.Left }) => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const isVisible =
    cssPBEnabled || settings.get(settingsParams.agendaIsEnabled);
  const title = isVisible ? settings.get(settingsParams.agendaTitleText) : null;

  return isVisible ? (
    <VisitorPageSection
      title={title}
      className={st(
        className,
        cssPBEnabled ? classes.opgAgendaIsEnabled : undefined,
      )}
      alignment={alignment}
    >
      <ChallengeAgenda alignment={alignment} />
    </VisitorPageSection>
  ) : null;
};

export const VisitorPageGroup: FCWithChildren<{
  className?: string;
  boxElementClassName?: string;
  alignment?: TextAlignment;
}> = ({
  className = null,
  boxElementClassName = null,
  alignment = TextAlignment.Left,
}) => {
  const { t } = useTranslation();
  const { socialGroup } = useSocialGroups();
  const settings = useSettings();
  const { experiments } = useExperiments();
  if (!socialGroup) {
    return null;
  }

  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const showTitle =
    cssPBEnabled ||
    settings.get(
      settingsParams[
        SettingsKeysAbbreviation.visitorPageToggleGroupDiscussionTitle
      ],
    );
  const title = showTitle
    ? settings.get(
        settingsParams[
          SettingsKeysAbbreviation.visitorPageGroupDiscussionTitle
        ],
      )
    : null;

  return (
    <VisitorPageSection
      title={title}
      className={st(
        className,
        cssPBEnabled && classes.opgVisitorPageGroupDiscussion,
      )}
      alignment={alignment}
    >
      <SocialGroup
        className={boxElementClassName}
        subTitle={t('live-site.groups.section-subtitle.not-joined')}
        showButton={false}
        alignment={alignment}
      />
    </VisitorPageSection>
  );
};

export const VisitorPageDetails: FCWithChildren<{
  className?: string;
  alignment?: TextAlignment;
}> = ({ className = null, alignment = TextAlignment.Left }) => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const showTitle =
    cssPBEnabled ||
    settings.get(
      settingsParams[SettingsKeysAbbreviation.visitorPageToggleAboutTitle],
    );
  const title = showTitle
    ? settings.get(
        settingsParams[SettingsKeysAbbreviation.visitorPageAboutTitle],
      )
    : null;

  const showJoinBtn =
    cssPBEnabled ||
    settings.get(
      settingsParams[
        SettingsKeysAbbreviation.visitorPageToggleAboutJoinButtonTitle
      ],
    );
  return (
    <VisitorPageSection
      title={title}
      className={st(className, cssPBEnabled && classes.opgVisitorPageAbout)}
      alignment={alignment}
    >
      <VisitorPageProgramDetails alignment={alignment} />

      {showJoinBtn ? (
        <VisitorPageJoinButtonBox
          className={st(
            className,
            cssPBEnabled
              ? classes.opgVisitorPageToggleAboutJoinButtonTitle
              : undefined,
          )}
          alignment={alignment}
          title={settings.get(
            settingsParams[
              SettingsKeysAbbreviation.visitorPageAboutJoinButtonTitle
            ],
          )}
        />
      ) : null}
    </VisitorPageSection>
  );
};

export const VisitorPagePricing: FCWithChildren<{
  className?: string;
  boxElementClassName?: string;
  alignment?: TextAlignment;
}> = ({
  className = null,
  boxElementClassName = null,
  alignment = TextAlignment.Left,
}) => {
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const { userType } = useUser();
  const { userPaidPlans } = useMemberPaidPlans();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const showTitle =
    cssPBEnabled ||
    settings.get(
      settingsParams[SettingsKeysAbbreviation.visitorPageTogglePriceTitle],
    );
  const title = showTitle
    ? settings.get(
        settingsParams[SettingsKeysAbbreviation.visitorPagePriceTitle],
      )
    : null;
  const {
    challengeJoinRestrictions,
    selectedPaymentOption,
    isDisableEligiblePlans,
    onPaymentSelected,
  } = useVisitorPageBase();

  const challengePaidPlans = getChallengePaidPlans(
    challenge?.id,
    userPaidPlans,
  );
  const isFree = isChallengeFree(
    challenge?.settings?.pricing as any,
    challengePaidPlans.length,
  );
  const showPrice =
    cssPBEnabled || settings.get(settingsParams.displayPriceForFreePrograms);
  const isVisible =
    (!isFree || showPrice) && !userTypeHandlers.isJoinedAlready(userType);
  const isDisabled =
    !!challengeJoinRestrictions?.length ||
    isPricingPickingForbidden(challenge as any, userType, userPaidPlans);

  const showBtn =
    cssPBEnabled ||
    settings.get(
      settingsParams[
        SettingsKeysAbbreviation.visitorPageTogglePriceJoinButtonTitle
      ],
    );
  return isVisible ? (
    <VisitorPageSection
      title={title}
      className={st(
        className,
        cssPBEnabled && isFree
          ? classes.opgDisplayPriceForFreePrograms
          : undefined,
      )}
      alignment={alignment}
    >
      <Pricing
        className={boxElementClassName}
        alignment={alignment}
        disabled={isDisabled}
        disabledEligible={isDisableEligiblePlans}
        selectedPaymentOption={selectedPaymentOption}
        onPaymentSelected={onPaymentSelected as any}
      />

      {showBtn ? (
        <VisitorPageJoinButtonBox
          className={st(
            className,
            cssPBEnabled && classes.opgVisitorPageTogglePriceJoinButtonTitle,
          )}
          alignment={alignment}
          title={settings.get(
            settingsParams[
              SettingsKeysAbbreviation.visitorPagePriceJoinButtonTitle
            ],
          )}
        />
      ) : null}
    </VisitorPageSection>
  ) : null;
};

export const VisitorPageStartDate: FCWithChildren<{
  className?: string;
  labelClassName?: string;
  alignment?: TextAlignment;
}> = ({
  className = null,
  labelClassName = null,
  alignment = TextAlignment.Left,
}) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const { userType } = useUser();
  const { userPaidPlans } = useMemberPaidPlans();
  const settings = useSettings();
  const {
    challengeJoinRestrictions,
    startDate,
    isEmptyStartDateError,
    getStartMinDate,
    onStartDateChange,
  } = useVisitorPageBase();
  const isVisible =
    !challengeJoinRestrictions?.length &&
    isNeedToSelectStartDate(challenge as any, userType, userPaidPlans);

  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const showTitle =
    cssPBEnabled ||
    settings.get(
      settingsParams[SettingsKeysAbbreviation.visitorPageToggleStartDayTitle],
    );
  const title = showTitle
    ? settings.get(
        settingsParams[SettingsKeysAbbreviation.visitorPageStartDayTitle],
      )
    : null;

  if (experiments.enabled(Challenges.hideStartDate)) {
    return null;
  }

  const showBtn =
    cssPBEnabled ||
    settings.get(
      settingsParams[
        SettingsKeysAbbreviation.visitorPageToggleStartDayJoinButtonTitle
      ],
    );
  return isVisible ? (
    <VisitorPageSection
      className={st(className, cssPBEnabled && classes.opgVisitorPageStartDay)}
      dataHook={visitorPageDataHooks.startDate()}
      title={title}
      alignment={alignment}
    >
      <label id="date-input" className={labelClassName}>
        {t('challenge.page.start-date-selection.description')}
      </label>

      <DateInput
        defaultValue={startDate}
        showError={isEmptyStartDateError}
        textAlignment={alignment}
        errorMessage={t(
          'challenge.page.start-date-selection.future-date-error',
        )}
        onChange={onStartDateChange as any}
        minDate={getStartMinDate()}
      />

      {showBtn ? (
        <VisitorPageJoinButtonBox
          className={st(
            className,
            cssPBEnabled && classes.opgVisitorPageToggleStartDayJoinButtonTitle,
          )}
          alignment={alignment}
          title={settings.get(
            settingsParams[
              SettingsKeysAbbreviation.visitorPageStartDayJoinButtonTitle
            ],
          )}
        />
      ) : null}
    </VisitorPageSection>
  ) : null;
};

export const VisitorPageInstructorsBox: FCWithChildren<{
  className?: string;
  alignment?: TextAlignment;
}> = ({ className = null, alignment = TextAlignment.Left }) => {
  const settings = useSettings();
  const {
    challengeData: { instructorsData },
  } = useChallengeData();
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const showOwner = cssPBEnabled || settings.get(settingsParams.displayOwner);
  const isVisible = showOwner && instructorsData?.instructors?.length;
  const showTitle =
    cssPBEnabled ||
    settings.get(
      settingsParams[SettingsKeysAbbreviation.visitorPageToggleInstructorTitle],
    );
  const title = showTitle
    ? settings.get(
        settingsParams[SettingsKeysAbbreviation.visitorPageInstructorTitle],
      )
    : null;

  const sidebarItems = useSidebarItems();
  const sidebarItemsIds = sidebarItems.map((item) => item.id);
  const isInsideSidebar = sidebarItemsIds.includes('instructor');

  return isVisible ? (
    <VisitorPageSection
      title={title}
      className={st(className, cssPBEnabled && classes.opgInstructorBox)}
      alignment={alignment}
    >
      <VisitorPageInstructors
        alignment={alignment}
        layout={isInsideSidebar ? 'column' : 'box'}
      />
    </VisitorPageSection>
  ) : null;
};

export const VisitorPageJoinButtonBox: FCWithChildren<
  {
    buttonId?: JoinButtonIds;
    className?: string;
    alignment?: TextAlignment;
    title?: string;
  } & React.HTMLAttributes<HTMLElement>
> = ({
  className = null,
  buttonId = JoinButtonIds.InsideSection,
  alignment = TextAlignment.Left,
  title,
  style,
}) => {
  const { userType } = useUser();
  const { challengeJoinRestrictions } = useVisitorPageBase();
  const isInsidePageSection = buttonId === JoinButtonIds.Bottom;

  if (
    userTypeHandlers.isJoinedAlready(userType) ||
    challengeJoinRestrictions?.length
  ) {
    return null;
  }

  const btn = (
    <VisitorPageJoinButton
      buttonId={buttonId}
      title={title}
      alignment={alignment}
      style={style}
      className={isInsidePageSection ? undefined : className}
    />
  );

  return isInsidePageSection ? (
    <VisitorPageSection
      className={className}
      alignment={alignment}
      style={style}
    >
      {btn}
    </VisitorPageSection>
  ) : (
    <>{btn}</>
  );
};

export const VisitorPageSectionsMapper: FCWithChildren<{
  sections: typeof sectionIds;
  sectionClassName?: string;
  boxElementClassName?: string;
  startDateLabelClassName?: string;
  alignment?: TextAlignment;
}> = ({
  sections,
  sectionClassName = null,
  boxElementClassName,
  startDateLabelClassName = null,
  alignment,
}) => {
  return (
    <>
      {sections.map((s, i) => {
        switch (s) {
          case 'about':
            return (
              <VisitorPageDetails
                key={i}
                className={sectionClassName}
                alignment={alignment}
              />
            );
          case 'overview':
            return (
              <VisitorPageAgenda
                key={i}
                className={sectionClassName}
                alignment={alignment}
              />
            );
          case 'instructor':
            return (
              <VisitorPageInstructorsBox
                key={i}
                className={sectionClassName}
                alignment={alignment}
              />
            );
          case 'start_date':
            return (
              <VisitorPageStartDate
                key={i}
                className={sectionClassName}
                labelClassName={startDateLabelClassName}
                alignment={alignment}
              />
            );
          case 'price':
            return (
              <VisitorPagePricing
                key={i}
                className={sectionClassName}
                boxElementClassName={boxElementClassName}
                alignment={alignment}
              />
            );
          case 'group_discussion':
            return (
              <VisitorPageGroup
                key={i}
                className={sectionClassName}
                boxElementClassName={boxElementClassName}
                alignment={alignment}
              />
            );
          case 'share':
            return (
              <VisitorPageShare
                key={i}
                className={sectionClassName}
                alignment={alignment}
              />
            );
        }
      })}
    </>
  );
};
